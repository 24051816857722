"use client";
import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ConfigProvider } from "antd";
import { AntdRegistry } from "@ant-design/nextjs-registry";
import { SessionProvider } from "next-auth/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import GlobalContextProvider from "@/src/context/globalContext";

const queryClient = new QueryClient();

const Provider = ({ children }) => {
  const theme = {
    cssVar: true,
    hashed: false,
    token: { colorPrimary: "#1677ff" },
    components: {
      Form: {
        itemMarginBottom: 10,
      },
      Statistic: {
        titleFontSize: 12,
        contentFontSize: 18,
      },
    },
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}>
      <QueryClientProvider client={queryClient}>
        <SessionProvider>
          <GlobalContextProvider>
            <AntdRegistry>
              <ConfigProvider theme={theme}>{children}</ConfigProvider>
            </AntdRegistry>
          </GlobalContextProvider>
        </SessionProvider>
      </QueryClientProvider>
    </GoogleReCaptchaProvider>
  );
};

export default Provider;
